import { useEffect, useReducer } from "react";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import back4app2 from "../back4app2";
import { AuthenticationError, AuthorizationError, NetworkError, User } from "@back4app2/sdk";
import { BACK4APP_DOT_COM_SITE_URL } from "../settings";

import newContainerLogo from "../assets/images/containers-logo.svg";
import styles from '../assets/styles/NewContainerFlow.module.css';
import { Button } from "@back4app2/react-components";
import StatusSpinner from "../components/StatusSpinner";
import StatusError from "../components/StatusError";

interface StripeReturnPageState {
  isVerifyingStripeSessionId: boolean;
  isVerifyingSessionIdSuccess: boolean,
  verifyingSessionIdError: string | undefined,
};

const INITIAL_STATE:  StripeReturnPageState = {
  isVerifyingStripeSessionId: true,
  isVerifyingSessionIdSuccess: false,
  verifyingSessionIdError: undefined,
};

enum StripeReturnPageActionType {
  Reset,
  StartVerifyingStripeSessionId,
  FinishVerifyingStripeSessionId,
}

const startVerifyingStripeSessionId = () => ({ type: StripeReturnPageActionType.StartVerifyingStripeSessionId } as const);
const finishVerifyingStripeSessionId = (success: boolean, errorMsg?: string) => ({ type: StripeReturnPageActionType.FinishVerifyingStripeSessionId, payload: {
  success,
  errorMsg
} } as const);

type StripeReturnPageAction = 
  | ReturnType<typeof startVerifyingStripeSessionId>
  | ReturnType<typeof finishVerifyingStripeSessionId>;

function reducer(state: StripeReturnPageState, action: StripeReturnPageAction): StripeReturnPageState {
  switch (action.type) {

    case StripeReturnPageActionType.StartVerifyingStripeSessionId:
      return {
        ...state,
        isVerifyingStripeSessionId: true,
      }
    
    case StripeReturnPageActionType.FinishVerifyingStripeSessionId: {
      return {
        ...state,
        isVerifyingSessionIdSuccess: action.payload.success,
        verifyingSessionIdError: action.payload.errorMsg,
        isVerifyingStripeSessionId: false,
      }
    }

    default:
      return state;
  }
}

const StripeReturnPage = () => {
  const { updateUser, user } = useOutletContext<{updateUser: (user: User) => void, user: User}>()
  const [searchParams] = useSearchParams();
  const stripeSessionId = searchParams.get('session_id');
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { isVerifyingSessionIdSuccess, isVerifyingStripeSessionId, verifyingSessionIdError } = state;

  useEffect(() => {

    if (!stripeSessionId) {
      return;
    }
    
    dispatch({ type: StripeReturnPageActionType.StartVerifyingStripeSessionId });

    let searchParamsChanged = false;
    
    (async () => {
      try {
        await back4app2.stripeSessionStatus(stripeSessionId);
        dispatch(finishVerifyingStripeSessionId(true));
      } catch (e) {
        if (searchParamsChanged) {
          console.error('error while checking session token', e);
        } else if (e instanceof NetworkError) {
          console.error('network error', e);
          dispatch(finishVerifyingStripeSessionId(false, 'Network error when verifying stripe session ID. Please check your internet connection or try again later.'));
        } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
          window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
        }else {
          console.error('unexpected error verifying session ID', e);
          dispatch(finishVerifyingStripeSessionId(false, 'Unexpected error when verifying session token Please try again later.'));
        }
      }

      if (!searchParamsChanged) {
        dispatch(finishVerifyingStripeSessionId(true));
      }
    })();
    return () => { 
      searchParamsChanged = true;
    };
  }, [stripeSessionId]);

  return (
    <div className='relative overflow-y-auto'>
      <div className={styles.container}>
      <div className={styles.header}  >
        <img src={newContainerLogo} alt="create new container" width="20" height="20" />
        <h1 className={styles.headerText}>Account Verified – Ready to Go!</h1>
      </div>
   

      <p className={styles.headerDesc}>You're all set! You can now deploy your applications on Back4App.</p>

      {/* main content */}
      <div className={`${styles.newContainerWrapper}`}>
      <div className="px-10 h-[300px] flex justify-center items-center flex-col">
        {isVerifyingStripeSessionId ? (<StatusSpinner />) : (isVerifyingSessionIdSuccess ? (<><div className="text-center">
          <div className="font-sora font-semibold text-lg leading-140 mb-4">✅ Verification Complete</div>
          <div className="font-inter text-sm text-light-grey">Your account is verified, and deployment is now unlocked. Need help? Our support team is here for you.</div>
        </div>
        <div className="flex justify-center space-x-2 mt-10">
          <Link to="/new-container">
            <Button
              type='primary'
              value='Start Deploying'
              className=''
              onClick={() => updateUser({ ...user, verification: { ...user.verification, isCardValidated: true }})}
            />
          </Link>
        </div></>) : (<><StatusError /> <br /> {verifyingSessionIdError}</>))}
      </div>
      </div>
    </div>
  </div>
  )
}

export default StripeReturnPage