import { Button } from "@back4app2/react-components";
import { useState } from "react";
import back4app2 from "../back4app2";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import Modal from "./Modal";
import { STRIPE_PUBLIC_KEY } from "../settings";
console.log(STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY as string);

const CreditCardValidation = ({ onCancel }: { onCancel: () => void}) => {
  const [showStripe, setShowStripe] = useState(false);
  const [options, setOptions] = useState<{ clientSecret: string }| null>(null);

  const onClick = async () => {
    setShowStripe(true);
    try {
      const response = await back4app2.createStripeSession();
      setOptions(() => ({
        clientSecret: response
      }))
      setShowStripe(true);
    } catch (err) {
      console.log('error in creating stripe session');
    }
  }

  return (
    <div className="px-10 h-[250px] flex justify-center items-center flex-col">
      <div className="text-center">
        <div className="font-sora font-semibold text-lg leading-140 mb-4">🔒Secure Your Account to Continue</div>
        <div className="font-inter text-sm text-light-grey">To ensure account security and prevent abuse, we need to verify your identity with a $1.00<br className="hidden md:block" /> temporary charge. Don&apos;t worry—this amount will be fully refunded after validation. <br /><br />Once verified, you&apos;ll have full access to deploy your web applications.</div>
      </div>
      <div className="flex justify-center space-x-2 mt-10">
        <Button
          type='secondary'
          value='Do it Later'
          className=''
          onClick={onCancel}
        />
        <Button
          type='primary'
          value='Validate Now'
          className=''
          onClick={onClick}
        />
      </div>
      
        {showStripe && options?.clientSecret ? (
          <Modal open={showStripe} showCloseIcon={false} minWidth="80%" innerPadding={false}>
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={options}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Modal>
      ) : null}
    </div>
  )
}

export default CreditCardValidation